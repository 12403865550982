//Bootstrap styles
@import "node_modules/bootstrap/scss/bootstrap";
//Custom settings --
@import "slick";

//Custom mixins
@import "mixins/mixins";

//Custom settings --
@import "settings";

//Component specific styles
@import "components/callout";

@import url("https://fonts.googleapis.com/css?family=Poppins:400,600,700&display=swap");

body {
  font-family: $main-font;
}

.header-wrapper {
  padding-bottom: 20vh;
  height: 100vh;
  background: #f8f8f8;

  .header {
    width: 100%;
    height: 100%;
    // background: linear-gradient(45deg, #e9664b, #df4941);
    background: url("../img/main-bg.png") no-repeat;
    background-size: cover;
    color: #fff;
    padding-top: 50px;
    position: relative;

    .navigation {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 100px;
      transition: 0.3s ease all;
      &.fixed {
        position: fixed;
        width: 100%;
        top: 0;
        left: 0;
        z-index: 1001;
        padding: 10px 0;
        background: linear-gradient(45deg, #5f5fc1, #ca262b);
        .logo {
          img {
            max-height: 70px;
          }
        }
      }
      .logo {
        img {
          max-height: 100px;
        }
      }
      #mobile-menu-toggle {
        display: none;
      }
      .menu {
        ul {
          list-style-type: none;
          li {
            float: left;
            font-size: 18px;
            font-weight: 600;
            margin-right: 25px;
            a {
              color: #fff;
              transition: 0.3s ease all;
              display: block;
              &:hover {
                color: rgba(255, 255, 255, 0.7);
                text-decoration: none;
              }
            }
            &:last-child {
              margin-right: 0;
            }
          }
        }
      }
    }

    .intro {
      padding: 0 140px;
      display: flex;
      align-items: center;
      justify-content: space-evenly;

      .screen {
        img {
          height: calc(80vh - 100px);
        }
      }
      .description {
        color: #fff;
        margin-bottom: 80px;

        h1 {
          font-size: 40px;
          line-height: 50px;
          font-weight: bold;
        }
        p {
          margin-top: 20px;
          font-size: 24px;
        }
        .store {
          margin-top: 50px;
          a {
            margin-right: 10px;
            box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.3);
            border-radius: 14px;
            display: inline-block;
            transition: 0.2s ease all;

            &:hover {
              transform: translate(-5px, -5px) rotate(-2deg);
            }

            img {
              height: 55px;
            }
          }
        }
      }
    }
  }
}

.how-it-works {
  background: #f8f8f8;
  padding: 100px 140px;

  .item {
    display: flex;
    align-items: center;
    margin-top: 70px;
    img {
      width: 160px;
      margin-right: 30px;
    }
    div.description {
      h3 {
        font-size: 26px;
        font-weight: bold;
        color: rgb(69, 69, 69);
        margin-bottom: 10px;
        margin-top: 10px;
      }
      p {
        font-size: 19px;
        line-height: 32px;
        color: rgb(102, 102, 102);
      }
    }
  }
  h2 {
    color: #e65d48;
    font-size: 42px;
    font-weight: bold;
  }
  p.description {
    color: #666666;
    font-size: 24px;
    width: 40%;
    margin: 0 auto;
  }
}

.shops-location {
  background: rgb(65, 103, 226);
  padding: 10vw 8vw 0 8vw;
  h2 {
    font-size: 42px;
    font-weight: bold;
    line-height: 52px;
    color: #fff;
  }
  p.description {
    font-size: 21px;
    line-height: 31px;
    color: #fff;
    border-left: 1px solid #fff;
    padding-left: 60px;
    padding-top: 30px;
    margin-left: 30px;
  }
  .whappo-stores {
    display: flex;
    align-items: flex-end;
    img {
      width: 100%;
    }
  }
}

.shops-contact {
  padding: 7vw 8vw 60px 8vw;
  background: linear-gradient(rgb(242, 242, 242), rgb(255, 255, 255));
  position: relative;
  overflow: hidden;
  h2 {
    font-size: 42px;
    font-weight: bold;
    line-height: 52px;
    color: rgb(69, 69, 69);
  }
  p.description {
    font-size: 21px;
    line-height: 34px;
    color: rgb(53, 53, 53);
    border-left: 1px solid rgb(69, 69, 69);
    padding-left: 130px;
    margin-left: 30px;
  }
  .circle1,
  .circle2,
  .circle3,
  .circle4 {
    position: absolute;
    border-radius: 50%;
  }
  .circle1 {
    border: 1px solid rgb(224, 76, 67);
    width: 52px;
    height: 52px;
    top: 70px;
    left: 120px;
  }
  .circle2 {
    border: 1px solid rgb(74, 144, 226);
    width: 52px;
    height: 52px;
    bottom: 95px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
  }
  .circle3 {
    border: 1px solid rgb(177, 55, 50);
    width: 44px;
    height: 44px;
    bottom: 260px;
    right: -14px;
  }
  .circle4 {
    border: 1px solid rgb(245, 166, 35);
    width: 77px;
    height: 77px;
    top: 240px;
    left: 0;
  }
}

.w-100 {
  width: 100%;
}

.download {
  // background: rgb(225, 76, 67);
  background: url("../img/download-bg.png") no-repeat;
  background-size: 100% 100%;
  padding: 100px 0;
  h2 {
    font-size: 42px;
    font-weight: bold;
    line-height: 52px;
    color: #fff;
  }
  div.description {
    padding-left: 40px;
    padding-top: 20px;
    margin-left: 30px;
    border-left: 1px solid #fff;
    p {
      color: #fff;
      font-size: 19px;
      line-height: 31px;
      margin-bottom: 40px;
    }
    .store-buttons {
      overflow: hidden;
      a {
        display: flex;
        align-items: center;
        float: left;
        margin-right: 30px;
        border: 1px solid #fff;
        border-radius: 12px;
        padding: 14px 26px 14px 16px;
        text-decoration: none;
        color: #fff;
        transition: 0.3s ease all;
        &:hover {
          background: #fff;
          color: rgb(225, 76, 67);
          svg {
            path {
              fill: rgb(225, 76, 67);
            }
          }
        }
        svg {
          width: 36px;
          height: 36px;
          margin-right: 20px;
        }
        .available {
          font-size: 22px;
          line-height: 20px;
          font-weight: bold;
          display: block;
          margin-top: 8px;
        }
        .store-text {
          font-size: 14px;
        }
      }
    }
  }
}

.testimonials {
  padding: 180px 0;
  position: relative;
  overflow: hidden;
  .test1,
  .test2,
  .test3,
  .test4,
  .test5 {
    opacity: 0.26;
    position: absolute;
    border-radius: 50%;
    overflow: hidden;
    img {
      width: 100%;
    }
  }
  .test1 {
    width: 60px;
    height: 60px;
    top: 240px;
    left: 80px;
  }
  .test2 {
    width: 80px;
    height: 80px;
    left: -40px;
    top: 470px;
  }
  .test3 {
    width: 100px;
    height: 100px;
    right: -53px;
    top: 220px;
  }
  .test4 {
    width: 70px;
    height: 70px;
    left: 160px;
    top: 620px;
  }
  .test5 {
    width: 70px;
    height: 70px;
    right: -35px;
    top: 570px;
  }
  .quote {
    opacity: 0.26;
    position: absolute;
    width: 100px;
    height: 86px;
    left: 340px;
    top: 450px;
    img {
      width: 100%;
    }
  }
  h2 {
    font-size: 42px;
    font-weight: bold;
    line-height: 52px;
    text-align: center;
    color: rgb(230, 93, 72);
    margin-bottom: 30px;
  }
  p.description {
    color: rgb(102, 102, 102);
    font-size: 24px;
    line-height: 34px;
    text-align: center;
    width: 50%;
    margin: 0 auto;
  }
  .slider {
    width: 50%;
    margin: 0 auto;
    margin-top: 100px;
    .slide-content {
      outline: none;
      img {
        width: 130px;
        height: 130px;
        border-radius: 50%;
        border: 10px solid rgba(197, 197, 197, 0.27);
        margin: 0 auto;
        margin-bottom: 10px;
      }
      h3 {
        color: rgb(69, 69, 69);
        font-size: 26px;
        line-height: 32px;
        text-align: center;
        font-weight: bold;
      }
      h4 {
        color: rgb(69, 69, 69);
        font-size: 21px;
        line-height: 32px;
        text-align: center;
        font-style: italic;
      }
      p {
        color: rgb(69, 69, 69);
        font-size: 21px;
        line-height: 32px;
        text-align: center;
      }
    }
  }
}

.slick-dots {
  overflow: hidden;
  display: flex;
  justify-content: center;
  margin-top: 40px;
  padding-inline-start: 0;
  li {
    list-style-type: none;
    float: left;
    margin-right: 40px;
    &.slick-active {
      button {
        width: 22px;
        height: 22px;
        background: rgb(224, 75, 66);
        color: rgb(224, 75, 66);
        border-radius: 12px;
      }
    }
    &:last-child {
      margin-right: 0;
    }
    button {
      background: rgb(232, 232, 232);
      border: 0;
      width: 12px;
      height: 12px;
      border-radius: 6px;
      font-size: 1px;
      color: rgb(232, 232, 232);
      outline: none;
    }
  }
}

#up-button {
  position: fixed;
  bottom: 50px;
  right: 50px;
  z-index: 1001;
  width: 75px;
  height: 60px;
  background: rgb(69, 69, 69);
  border-radius: 6px;
  cursor: pointer;
  transition: 0.3s ease all;
  display: none;
  img {
    width: 36px;
  }
  &.active {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.subscribe {
  h3 {
    font-size: 21px;
    line-height: 27px;
    font-weight: bold;
    color: rgb(69, 69, 69);
    margin-bottom: 10px;
    margin-left: 30px;
    span {
      color: rgb(224, 75, 66);
    }
  }
  p.description {
    color: rgb(102, 102, 102);
    font-size: 16px;
    line-height: 24px;
    margin-left: 30px;
  }
  .email-input {
    box-shadow: 10px 20px 60px 0 rgba(0, 0, 0, 0.21);
    padding: 16px 20px;
    display: flex;
    align-items: center;
    border-radius: 5px;
    margin-bottom: -46px;
    background: #fff;
    input {
      width: 100%;
      font-size: 18px;
      outline: none;
      border: 0;
      padding: 10px;
    }
    button {
      width: 100%;
      background-color: rgb(224, 75, 66);
      font-size: 21px;
      font-weight: bold;
      color: #fff;
      border: none;
      height: 60px;
      border-radius: 6px;
    }
  }
}

.footer {
  padding: 130px 0 30px 0;
  background: rgb(241, 241, 241);

  .location {
    display: flex;
    align-items: center;
    text-align: center;
    img {
      width: 32px;
      margin-right: 10px;
    }
    .address1 {
      display: block;
      font-size: 16px;
      font-weight: bold;
      color: rgb(69, 69, 69);
    }
    .address2 {
      font-size: 14px;
      color: rgb(102, 102, 102);
    }
  }

  .copyright {
    font-size: 12px;
    color: rgb(69, 69, 69);
    text-align: center;
    margin-top: 20px;
  }

  .shop-owner {
    margin-top: 40px;
    padding: 8px 16px;
    border-radius: 5px;
    background: #4167e2;
    color: #fff;
    box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    text-decoration: none;
    transition: 0.2s ease all;

    &:hover {
      background: lighten(#4167e2, 10%);
    }
  }

  .footer-menu {
    margin-top: 40px;
    ul {
      list-style-type: none;
      padding-inline-start: 0;
      li {
        float: left;
        font-size: 16px;
        font-weight: bold;
        margin-right: 25px;
        a {
          color: rgb(69, 69, 69);
          text-decoration: none;
          &:hover {
            color: rgba(69, 69, 69, 0.7);
          }
        }
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .header-wrapper {
    height: initial !important;
    padding-bottom: 0 !important;

    .header {
      height: initial !important;
      background: linear-gradient(45deg, #5e60c4, #c0465c);
      padding-top: 10px;
      padding-bottom: 10px;

      .navigation {
        margin-bottom: 20px;
        margin-top: 20px;
        #mobile-menu-toggle {
          position: fixed;
          z-index: 1002;
          top: 30px;
          right: 16px;
          width: 48px;
          height: 48px;
          display: flex;
          border-radius: 50%;
          background: #fff;
          align-items: center;
          justify-content: center;
          box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.4);
          img {
            width: 28px;
            height: 28px;
          }
          .close-menu-icon {
            display: none;
          }
          .menu-icon {
            display: block;
          }
          &.active {
            box-shadow: none;
            .close-menu-icon {
              display: block;
            }
            .menu-icon {
              display: none;
            }
          }
        }
        .menu {
          display: none;
          position: fixed;
          z-index: 1001;
          left: 0;
          top: 0;
          width: 100%;
          background: #fff;
          box-shadow: 0px 0px 18px rgba(0, 0, 0, 0.4);
          ul {
            margin-bottom: 16px;
            padding-inline-start: 0;
            text-align: center;
            padding-top: 60px;
            padding-bottom: 20px;
            li {
              float: none;
              margin-right: 0;
              margin-top: 16px;
              a {
                color: #000;
                &:hover {
                  transform: none;
                  text-shadow: none;
                }
              }
            }
          }
        }
      }

      .intro {
        padding: 0 16px !important;
        flex-direction: column;
        .screen {
          order: 2;
          margin-top: 24px;
          img {
            position: relative;
            right: initial;
            bottom: initial;
            width: 100%;
            height: auto !important;
          }
        }
        .description {
          margin-bottom: 20px;
          h1 {
            font-size: 26px;
            line-height: 32px;
          }
          p {
            font-size: 16px;
          }
          .store {
            margin-top: 30px;
            a {
              margin-right: 0;
              margin-bottom: 10px;
              img {
                height: 50px;
              }
            }
          }
        }
      }
    }
  }
  .how-it-works {
    padding: 40px 16px;
    h2 {
      font-size: 27px;
    }
    p.description {
      width: 100%;
      font-size: 16px;
    }
    .item {
      margin-top: 24px;
      padding: 0;
      img {
        width: 100px;
        margin-right: 10px;
      }
      div.description {
        h3 {
          font-size: 17px;
        }
        p {
          font-size: 12px;
          line-height: 20px;
        }
      }
    }
  }
  .shops-location {
    padding: 40px 16px;
    .whappo-stores {
      display: none;
    }
    h2 {
      font-size: 27px;
      line-height: 33px;
    }
    p.description {
      font-size: 14px;
      line-height: 21px;
      padding-left: 20px;
      padding-top: 10px;
      padding-bottom: 10px;
      margin-left: 10px;
      margin-bottom: 0;
    }
  }
  .shops-contact {
    padding: 40px 16px;
    h2 {
      font-size: 27px;
      line-height: 33px;
    }
    p.description {
      font-size: 14px;
      line-height: 21px;
      padding-left: 20px;
      padding-top: 10px;
      padding-bottom: 10px;
      margin-left: 10px;
      margin-bottom: 0;
    }
  }
  .download {
    background: rgb(225, 76, 67);
    padding: 40px 16px;
    h2 {
      font-size: 27px;
      line-height: 33px;
    }
    div.description {
      padding-left: 20px;
      padding-top: 10px;
      padding-bottom: 10px;
      margin-left: 10px;
      margin-bottom: 0;
      p {
        font-size: 14px;
        line-height: 21px;
        margin-bottom: 24px;
      }
      .store-buttons {
        a {
          margin-bottom: 10px;
          padding: 8px 16px 8px 10px;
          svg {
            width: 24px;
            height: 24px;
            margin-right: 8px;
          }
          .available {
            font-size: 15px;
            line-height: 14px;
          }
          .store-text {
            font-size: 12px;
          }
        }
      }
    }
  }
  .testimonials {
    padding: 40px 16px;
    .test1,
    .test2,
    .test3,
    .test4,
    .test5,
    .quote {
      display: none;
    }
    h2 {
      font-size: 27px;
      line-height: 33px;
      margin-bottom: 16px;
    }
    p.description {
      font-size: 16px;
      line-height: 22px;
      width: 100%;
    }
    .slider {
      width: 100%;
      margin-top: 50px;
      .slide-content {
        h3 {
          font-size: 17px;
          line-height: 21px;
        }
        h4 {
          font-size: 14px;
          line-height: 17px;
        }
        p {
          font-size: 14px;
          line-height: 17px;
        }
      }
    }
  }
  .slick-dots {
    margin-top: 10px;
  }
  .subscribe {
    padding: 40px 16px;
    h3 {
      font-size: 14px;
      line-height: 18px;
      margin-left: 0;
    }
    p.description {
      font-size: 11px;
      line-height: 16px;
      margin-left: 0;
    }
    .email-input {
      padding: 0;
      margin-bottom: 0;
      padding: 10px 4px;
      input {
        font-size: 12px;
      }
      button {
        font-size: 14px;
        height: 40px;
      }
    }
  }
  .footer {
    padding: 40px 16px;
    .location {
      margin-bottom: 16px;
      .address1 {
        font-size: 12px;
      }
      .address2 {
        font-size: 10px;
      }
    }
    .footer-menu {
      margin-top: 20px;

      ul {
        li {
          font-size: 12px;
        }
      }
    }
  }
  #up-button {
    bottom: 16px;
    right: 16px;
    width: 50px;
    height: 40px;
    img {
      width: 24px;
    }
  }
}

#shop-form {
  margin-top: 40px;
  position: relative;

  &.loading {
    &::before {
      content: "Yükleniyor...";
      position: absolute;
      left: 0;
      top: 0;
      z-index: 11;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background: rgba(255, 255, 255, 0.7);
    }
  }
}
